<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <ParentForm ref="form" :isEdit="true"/>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li v-if="$permission.isGmo()" class="listGrid-item">
            <button
              class="btn btn-red"
              type="button"
              :disabled="!parentEventDetail.isPossibleDelete"
              @click="showModal"
            >削除する</button>
          </li>
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item">
                <router-link class="btn btn-white router-link-active" :to="{ name: 'SettingEvent' }">キャンセル</router-link>
              </li>
              <li class="listGrid-item">
                <ActionButton class="btn btn-blue" :handle-submit="edit" button-text="編集する" />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal" v-if="modal && parentEventDetail.isPossibleDelete">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">親イベントを削除すると、顧客や店舗などその親イベント内の情報も削除されます。<br>削除された情報は元に戻すことができません。</p>
        <p class="description text-align-center">この親イベントを削除しますか？</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-red" :handle-submit="handleDeleteEvent" button-text="削除する" />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//components
import ParentForm from '../../Form/Parent.vue';
import Modal from '@/components/Modal.vue';
//mixins
import modal from '@/mixins/plugin/modal';
import nav from '@/mixins/nav/setting';

export default {
  name: 'SettingEventParentEdit',
  data: function () {
    return {
      pageName: '設定',
    };
  },
  components: {
    ParentForm,
    Modal,
  },
  computed: {
    ...mapGetters({
      parentEventDetail: 'event/parentEventDetail',
    }),
  },
  mixins: [nav, modal],
  methods: {
    edit: function () {
      this.$refs.form.handleSubmit();
    },
    async handleDeleteEvent() {
      const result = await this.$store.dispatch('event/deleteParentEvent', {
        subdomain: this.$route.params.subdomain,
      });
      this.closeModal();
      if (result) {
        await this.$router.push({ name: 'SettingEvent' });
        this.$message.deleted('parentEvent');
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('event/RESET_PARENT_EVENT_DETAIL');
  },
};
</script>
